export const HOME_LANDING = '/home';
export const STYLE = '/home/user';
export const USER = '/home/user';
export const DASHBOARD='/home/dashboard';
export const NEWDONATION='/home/newdonation';
export const NEWDONATIONUKAAZ='/home/newdonation-ucaaz';
export const LOGIN='/';
export const CHANNELS='/home/channels';
export const AREAS='/home/areas';
export const CENTERS='/home/centers';
export const ROLES='/home/roles';
export const DONATION_TYPES='/home/donationtypes';
export const DONATION_METHODS='/home/donationmethods';
export const DONATION_PURPOSE='/home/donationpurpose';
export const DONATIONS='/home/donations';
export const REQUESTS='/home/requests';
export const REPORTS_FIXED='/home/reports';
export const REPORTS_CUSTOM='/home/customreports';
export const REPORTS_DONATION='/home/donationreports';
export const PROFILE='/home';
export const USER_LEGER='/userledger';
export const SETTLEMENT_REQUESTS='/home/settlementrequests';
export const DONATION_HOME_COLLECTION='/home/homecollection';

export const DONATION_LINE_ALL='/home/donations/all';
export const DONATION_LINE_NEW='/home/donations/new';
export const DONATION_LINE_CREDIT_CARD='/home/donations/creditcard';
export const DONATION_LINE_BANK_TRANSFER='/home/donations/banktransfer';
export const DONATION_LINE_HOME_PICK_UP='/home/donations/homepickup';
export const DONATION_LINE_RECIEPT='/home/donations/reciept';
export const DONATION_LINE_VERIFY='/home/donations/verify';
export const DONATION_LINE_COMP_TRAN='/home/donations/completetransactions';
export const DONATION_LINE_DONE='/home/donations/done';
export const DONATION_LINE_AKFP='/home/donations/AKFP';
export const DONATION_LINE_DUPLICATE='/home/donations/mistake';
export const DONATION_LINE_LOST='/home/donations/lost';
export const DONATION_LINE_OLD_DATA='/home/donations/olddata';
export const DONATION_LINE_AKFPUS='/home/donations/AKFUS';
export const DONATION_LINE_AKFPUK='/home/donations/AKFUK';

export const EXPORT_DONATION_DETAILS='/home/export/donationdetails'